











































import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {Component, Ref, Prop, Vue, Watch} from 'vue-property-decorator';
import {AgGridVue} from 'ag-grid-vue';

import {SettingColumnService} from '@/services/SettingColumnService';
import {formatNumber} from "@/common";
import {SettingColumnResult, SettingColumnSelect} from "@/models/settingColumn/SettingColumnResult";
import {ActionResult} from '@/models/ActionResult';

@Component({
    components: {
        VuePerfectScrollbar,
        AgGridVue,
        SettingColumns: () => import(/* webpackChunkName: "SettingColumns" */ '@/components/SettingColumns.vue'),
    }
})
export default class OrderDetailSideBar extends Vue {
    @Ref('agGridTable') agGridTable!: any;
    @Ref('settingColumnsRef') settingColumnsRef!: any;
    @Prop({required: true}) data!: any;

    private settingColumnService: SettingColumnService;

    constructor(){
        super();

        this.settingColumnService = new SettingColumnService();
    }

    active: Boolean = false;
    columnDefs: any = null;
    gridOptions: any = null;
    gridApi: any = null;
    columnApi: any = null;
    gridColumnApi: any = null;

    settingColumnConfig: SettingColumnResult = new SettingColumnResult();
    defaultSettingColumns: any = [];
    settingColumnModule: string = "OrderDetailSideBar";

    defaultColDef = {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
        filter: true,
    };

    beforeMount(){
        this.gridOptions = {
            suppressCellSelection: false,
            localeText: {
                noRowsToShow: 'Không có dữ liệu',
            }
        };

        this.columnDefs = [
            {
                headerName: 'Mã hàng',
                field: 'product.code',
                width: 150,
                filter: true,
            },
            {
                headerName: 'Tên hàng',
                field: 'product.name',
                filter: true,
                width: 400,
                // pinned: 'left'
            },
            // {
            //     headerName: 'Đơn vị tính',
            //     field: 'company',
            //     filter: true,
            //     width: 140,
            // },
            {
                headerName: 'Số lượng',
                field: 'quantity',
                filter: true,
                width: 120,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                }
            },
            {
                headerName: 'Thực xuất',
                field: 'actualExport',
                filter: true,
                width: 130,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                }
            },
            {
                headerName: 'Đơn giá',
                field: 'price',
                filter: true,
                width: 150,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                }
            },
            {
                headerName: 'Chiết khấu',
                field: 'discount',
                filter: true,
                width: 130,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                }
            },
             {
                headerName: 'VAT',
                field: 'vatMoney',
                filter: true,
                width: 130,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                }
            },
            {
                headerName: 'Thành tiền',
                field: 'totalPrice',
                filter: true,
                width: 140,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                }
            },
            // {
            //     headerName: 'Kho hàng',
            //     field: 'country',
            //     filter: true,
            //     width: 150,
            // },
            {
                headerName: 'Ghi chú',
                field: 'note',
                filter: true,
                width: 150,
            }
        ];

        this.getDefaultColumnSetting();
    }

    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
    }

    open(){
        this.active = !this.active;

        this.settingColumnService.getByModuleName(this.settingColumnModule).then((result: ActionResult<SettingColumnResult>) => {
            this.settingColumnConfig = result.data as SettingColumnResult;
            if (!this.settingColumnConfig) {
                this.settingColumnConfig = new SettingColumnResult(this.settingColumnModule);
                this.settingColumnConfig.columnConfigData = this.defaultSettingColumns;
            }

            if (this.settingColumnsRef) {
                this.settingColumnsRef.agGridRefresh(this.settingColumnConfig);
            }
        });
    }

    get rowData(){
        return this.data.orderDetails;
    }

    openSettingColumnModal() {
        this.settingColumnsRef.openSettingColumnModal();
    }

    settingColumnUpdated(config: SettingColumnResult) {
        this.settingColumnConfig = config;
        this.gridApi.sizeColumnsToFit();
    }

    private getDefaultColumnSetting() {
        this.defaultSettingColumns = [
            new SettingColumnSelect('Code', 'product.code', true),
            new SettingColumnSelect('Name', 'product.name', true),
            new SettingColumnSelect('Quantity', 'quantity', true),
            new SettingColumnSelect('ActualExport', 'actualExport', true),
            new SettingColumnSelect('Price', 'price', true),
            new SettingColumnSelect('Discount', 'discount', true),
            new SettingColumnSelect('VatMoney', 'vatMoney', true),
            new SettingColumnSelect('TotalPrice', 'totalPrice', true),
            new SettingColumnSelect('Note', 'note', true)
        ]
    }
}
